<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>DATA INSIDE TALK </strong><small>Tambah Data</small>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="inputInsideTalk">
            <CRow>
              <CCol sm="12">
              <CInput
                v-model="title"
                label="Judul "
                placeholder="Input Judul "
              />
              </CCol>
              <CCol sm="6">
                <CTextarea
                  label="Embedded Code Youtube "
                  placeholder="Input Embedded Code Youtube "
                  v-model="url"
                  rows="5"
                />
              </CCol>
              <CCol sm="6">
                <CTextarea
                  label="Deskripsi Youtube "
                  placeholder="Input Deskripsi Youtube "
                  v-model="link"
                  rows="5"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                type="text"
                v-model="youtube_id"
                label="Youtube ID"
                placeholder="Input Youtube ID "
              />
              </CCol>
              <CCol sm="6">
                <div class="form-group">
                <label> Status</label>
                <select v-model="status" placeholder='Pilih Status' class="form-control">
                  <option value="" disabled selected>Pilih Status</option>
                  <option value="1">Aktif</option>
                  <option value="0">Non-Aktif</option>
                </select>
                </div>
              </CCol>      
            </CRow>          
                <div class="form-actions">
                  <CButton type="submit" color="primary" style='margin-right:15px'>Simpan</CButton>
                  <CButton color="secondary" to="/rubrik">Batal</CButton>
                </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      title="Error Input"
      color="danger"
      id="modalError"
    >
      <b>Error</b>! Data InsideTalk gagal diinput.
    </CModal>
   
  </div>
</template>
<script>
import axios from 'axios';
// import * as Modals from '../notifications/Modals.vue'
// import { CurrencyInput } from 'vue-currency-input'

export default {
  // components: {CurrencyInput },
  name: 'Forms',
  data () {
    return {
      title : "",
      url : "",
      link : "",
      youtube_id : "",
      status : "",
      // fproduct_price : "",
      // qty : "",
      // barcode : "",
      // alert : "",
      // product_des : "",
      // unit : "",
      selected: [], // Must be an array reference!
      show: true,
    }
  },
  methods: {
    // validator (val) {
    //   return val ? val.length >= 4 : false
    // },
    inputInsideTalk: function(){
      // Simple POST request with a JSON body using axios
      const rubrik = { 
                          name:this.name,
                          position: this.position, 
                          status: this.status, 
                          };
      // alert(JSON.stringify(rubrik));
      axios.post(process.env.VUE_APP_BASE_URL+"rubrik/insert", rubrik)
        .then((response) => {
            // console.log(response);
            // alert(response.data);
            if(response.status==200){
              this.$router.push('/rubrik');
            }
            else{
              this.$root.$emit("bv::show::modal", "modalError");
            }            
        })
    }
  }
}
</script>
